import React, { createContext, useContext, useState } from 'react';
import { toast } from 'react-toastify';
import { services } from '../api/feathers';

const RoomsContext = createContext();

export function RoomsProvider({ children }) {
  const [rooms, setRooms] = useState([]);

  function getRoom(roomId) {
    services.rooms.get(roomId)
      .then((response) => setRooms([response]));
  }

  function findRooms(accountId) {
    services.rooms.find({
      query: {
        accountId,
        includeDevices: true,
      },
    }).then((response) => setRooms(response.data));
  }

  async function createRooms(room) {
    const { name, accountId } = room;

    services.rooms.create({ accountId, name })
      .then(() => {
        findRooms(accountId);
      });
  }

  async function patchRooms(room) {
    const { name } = room;

    return services.rooms.patch(room.id, { name })
      .then(() => {
        findRooms(room.accountId);
      });
  }

  async function removeRooms(roomId) {
    const room = rooms.find((rm) => rm.id === roomId);

    const { accountId, devices = [] } = room || {};
    console.log('devices', devices);
    if (devices.length > 0) {
      toast.error('Exclusao não permitida para cômodos com dispositivos cadastrados.');
      return false;
    }
    return services.rooms.remove(roomId)
      .then(() => {
        findRooms(accountId);
      });
  }

  return (
    <RoomsContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        rooms,
        findRooms,
        createRooms,
        patchRooms,
        getRoom,
        removeRooms,
      }}
    >
      { children }
    </RoomsContext.Provider>
  );
}

export function useRooms() {
  const context = useContext(RoomsContext);

  return context;
}
