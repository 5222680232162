import React, { createContext, useContext, useState } from 'react';
import { services } from '../api/feathers';
import { useAccounts } from './useAccounts';

const SwitchControlContext = createContext();

export function SwitchControlProvider({ children }) {
  const [switchControls, setSwitchControls] = useState([]);
  const [switchControlsWithReturn, setSwitchControlsWithReturn] = useState([]);
  const { account } = useAccounts();
  const idAccount = account?.id;

  function findSwitchControlsWithReturn(accountId) {
    services.switchControls.find({
      query: {
        accountId,
        includeFourwaySlaves: true,
        withoutReturn: false,
      },
    }).then((response) => setSwitchControlsWithReturn(response.data.filter((sc) => !sc.fourwayMasterId)));
  }

  function findSwitchControls(deviceId) {
    services.switchControls.find({
      query: {
        deviceId,
      },
    }).then((response) => setSwitchControls(response.data));
  }

  async function createSwitchControls(switchControl) {
    const { deviceId } = switchControl;

    services.switchControls.create(switchControl)
      .then(() => {
        findSwitchControls(deviceId);
        findSwitchControlsWithReturn(idAccount);
      });
  }

  async function patchSwitchControls(id, switchControl) {
    return services.switchControls.patch(id, switchControl);
  }

  // eslint-disable-next-line no-unused-vars
  async function removeSwitchControls(switchControl) {
    services.switchControls.remove(switchControl.id)
      .then(() => {
        findSwitchControls(switchControl.deviceId);
      });
  }

  return (
    <SwitchControlContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        switchControls,
        switchControlsWithReturn,
        findSwitchControls,
        createSwitchControls,
        patchSwitchControls,
        findSwitchControlsWithReturn,
      }}
    >
      { children }
    </SwitchControlContext.Provider>
  );
}

export function useSwitchControls() {
  const context = useContext(SwitchControlContext);

  return context;
}
