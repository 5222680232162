import { React, useState } from 'react';
import { Form, Button } from 'react-bootstrap';
import {
  ContainerLogin,
  LoginButton,
  LogoSmartek,
} from './styles';
import logoSmartekImg from '../../assets/smartek.svg';
import { useAuth } from '../../contexts/useAuth';

export default function Login() {
  const {
    user, token, login, logout,
  } = useAuth();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  function handleSubmitLogin(e) {
    e.preventDefault();
    login(email, password);
  }

  if (token) {
    return (
      <>
        <h1>
          {user.name}
          , voce está logado!
        </h1>
        <Button onClick={() => logout()}>Sair</Button>
      </>
    );
  }

  return (
    <ContainerLogin>
      <LogoSmartek src={logoSmartekImg} alt="Logo Smartek" />
      <Form onSubmit={handleSubmitLogin}>
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label>Email</Form.Label>
          <Form.Control
            type="email"
            placeholder="Email"
            value={email}
            onChange={(event) => setEmail(event.target.value)}
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="formBasicPassword">
          <Form.Label>Senha</Form.Label>
          <Form.Control
            type="password"
            placeholder="Senha"
            value={password}
            onChange={(event) => setPassword(event.target.value)}
          />
        </Form.Group>
        <LoginButton variant="primary" type="submit">
          Login
        </LoginButton>
      </Form>
    </ContainerLogin>

  );
}
