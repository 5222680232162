import styled from 'styled-components';
import { Button } from 'react-bootstrap';

export const ContainerLogin = styled.div`
  flex-direction: column;
  display: flex;
  align-items: center;
  text-align: left;
`;

export const LoginButton = styled(Button)`
  width: 100%;
`;

export const LogoSmartek = styled.img`
  margin: 20px 0 30px 0;
  width: 75px;
`;
