import React from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.min.css';

import Modal from 'react-modal';
import { ToastContainer } from 'react-toastify';
import { AuthProvider } from './contexts/useAuth';
import { AccountsProvider } from './contexts/useAccounts';
import { RoomsProvider } from './contexts/useRooms';
import { DevicesProvider } from './contexts/useDevices';
import { SwitchControlProvider } from './contexts/useSwitchControls';

import Navigation from './components/Navigation';
import { SmartplugControlProvider } from './contexts/useSmartplugControls';

Modal.setAppElement('#root');

Modal.setAppElement('#root');

function App() {
  return (
    <AuthProvider>
      <AccountsProvider>
        <RoomsProvider>
          <SwitchControlProvider>
            <SmartplugControlProvider>
              <DevicesProvider>
                <div className="App">
                  <header className="App-header">
                    <Navigation />
                    <ToastContainer position="top-center" />
                  </header>
                </div>
              </DevicesProvider>
            </SmartplugControlProvider>
          </SwitchControlProvider>
        </RoomsProvider>
      </AccountsProvider>
    </AuthProvider>
  );
}

export default App;
