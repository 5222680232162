import {
  React, useCallback, useEffect, useState,
} from 'react';
import {
  Form,
  Button,
  FloatingLabel,
  Row,
  Col,
  Card,
  ListGroup,
  ListGroupItem,
  Container,
  InputGroup,
  Badge,
  Modal as ModalDialog,
} from 'react-bootstrap';
import Modal from 'react-modal';
import { useParams, useHistory } from 'react-router-dom';
import { QrReader } from 'react-qr-reader';
import _ from 'lodash';
import { BsLightbulbOff, BsLightbulb } from 'react-icons/bs';
import {
  MdOutlineEdit,
  MdArrowBackIos,
  MdOutlineAdd,
} from 'react-icons/md';
import { GoRepoForked } from 'react-icons/go';
import { ImQrcode } from 'react-icons/im';
import { toast } from 'react-toastify';

import { useDevices } from '../../contexts/useDevices';
import { useRooms } from '../../contexts/useRooms';
import {
  ContentDevices,
  DeviceButton,
  FooterContent,
  QrCancelButton,
  LabelDeviceType,
  DeviceNameContent,
  ContentTitle,
  BackRoomsTitle,
  AddDeviceTitle,
  NameRoomTitle,
} from './styles';
import { useSwitchControls } from '../../contexts/useSwitchControls';
import { useAccounts } from '../../contexts/useAccounts';
import { useSmartplugControls } from '../../contexts/useSmartplugControls';
import MainNavbar from '../MainNavbar';

export default function ListDevices() {
  const [modalNewDeviceIsOpen, setModalNewDeviceIsOpen] = useState(false);
  const [modalDeleteDeviceIsOpen, setModalDeleteDeviceIsOpen] = useState(false);
  const [showQrCodeReader, setShowQrcodeReader] = useState(false);
  const [switchControleEditId, setSwitchControleEditId] = useState();
  const [smartplugControleEditId, setSmartplugControleEditId] = useState();
  const [editControlName, setEditControlName] = useState();
  const [editControlWithoutReturn, setEditControlWithoutReturn] = useState();
  const [editControlMasterId, setEditControlControlMasterId] = useState();
  const [newDevice, setNewDevice] = useState({});
  const { account, getAccount } = useAccounts();
  const history = useHistory();
  const { uuid, roomId } = useParams();
  const { rooms, getRoom } = useRooms();
  const { patchSwitchControls } = useSwitchControls();
  const { patchSmartplugControls } = useSmartplugControls();
  const {
    devices,
    removeDevices,
    findDevices,
    patchDevices,
    createDevices,
    validateDevicesRegistrations,
  } = useDevices();
  const {
    switchControlsWithReturn,
    findSwitchControlsWithReturn,
  } = useSwitchControls();

  function closeModal() {
    setModalNewDeviceIsOpen(false);
    setNewDevice({});
  }

  function handleSubmitDevice(e) {
    e.preventDefault();
    const { name, id } = newDevice;
    if (!name) {
      toast.warn('Informe o nome do dispositivo');
    } else {
      console.log(newDevice);
      if (id) {
        patchDevices(newDevice)
          .then(() => {
            closeModal();
          });
      } else {
        createDevices({ roomId, ...newDevice })
          .then((response) => {
            console.log('response', response);
            closeModal();
          }).catch((error) => {
            toast.error(error.message);
          });
      }
    }
  }

  function handleOpenEditDevice(room) {
    console.log('NewDevice: ', room);
    setNewDevice(room);
    setModalNewDeviceIsOpen(true);
  }

  async function handleScan(data) {
    try {
      if (data) {
        console.log('INICIO VALIDACAO DEVICE REGISTRATIONS', data.mac);

        const qrDevice = JSON.parse(data);
        await validateDevicesRegistrations(qrDevice.mac);
        console.log('FIM VALIDACAO DEVICE REGISTRATIONS');
        const controls = [];
        const deviceType = String(qrDevice.deviceType).toLowerCase();

        if (deviceType === 'smartplug') {
          controls.push({
            switchIndex: 1,
            name: `${newDevice.name} Canal ${1}`,
          });
        }

        for (let index = 0; index < qrDevice.nOfChanels; index++) {
          controls.push({
            switchIndex: index + 1,
            name: `${newDevice.name} Canal ${index + 1}`,
            withoutReturn: false,
          });
        }

        setNewDevice({
          ...newDevice, ...qrDevice, deviceType, controls,
        });
        setShowQrcodeReader(false);
      }
    } catch (error) {
      toast.error(error.message);
    }
  }

  function handleChangeControlIsReturn(index, withoutReturn) {
    const controls = newDevice.controls.filter((ch) => ch.switchIndex !== index);
    const control = newDevice.controls.find((ch) => ch.switchIndex === index);
    const changedControl = { ...control, withoutReturn };
    setNewDevice({ ...newDevice, controls: _.sortBy([...controls, changedControl], (ch) => ch.switchIndex) });
  }

  function handleChangeControlName(index, name) {
    const controls = newDevice.controls.filter((ch) => ch.switchIndex !== index);
    const control = newDevice.controls.find((ch) => ch.switchIndex === index);
    const changedControl = { ...control, name };
    setNewDevice({ ...newDevice, controls: _.sortBy([...controls, changedControl], (ch) => ch.switchIndex) });
  }

  function handleEnableSwitchControlEdit(id, name, withoutReturn, fourwayMasterId) {
    setEditControlName(name);
    setEditControlWithoutReturn(withoutReturn);
    setSwitchControleEditId(id);
    setEditControlControlMasterId(fourwayMasterId);
  }

  function handleCancelSwitchControlEdit() {
    setEditControlName(null);
    setEditControlWithoutReturn(null);
    setSwitchControleEditId(null);
    setEditControlControlMasterId(null);
  }

  function handleSaveSwitchControlEdit(id) {
    console.log({ id, editControlName });
    patchSwitchControls(id, {
      name: editControlName,
      withoutReturn: editControlWithoutReturn,
      fourwayMasterId: editControlMasterId,
    })
      .then(() => {
        handleCancelSwitchControlEdit();
        findDevices(roomId);
      }).catch((error) => {
        toast.error(error.message);
      });
  }

  function handleEnableSmartplugControlEdit(id, name) {
    setEditControlName(name);
    setSmartplugControleEditId(id);
  }

  function handleCancelSmartplugControlEdit() {
    setEditControlName(null);
    setSmartplugControleEditId(null);
  }

  function handleSaveSmartplugControlEdit(id) {
    console.log({ id, editControlName });
    patchSmartplugControls(id, {
      name: editControlName,
    })
      .then(() => {
        handleCancelSmartplugControlEdit();
        findDevices(roomId);
      }).catch((error) => {
        toast.error(error.message);
      });
  }

  async function handleRemoveDevice() {
    setModalDeleteDeviceIsOpen(false);
    closeModal();
    try {
      await removeDevices(newDevice?.id);
      toast.info('Dispositivo excluido com sucesso');
    } catch (error) {
      toast.error('Erro ao excluir dispositivo.');
    }
  }

  function handleError(err) {
    console.error(err);
  }

  const getSwitchControlsWithReturnCallback = useCallback(() => {
    if (account && account?.id) {
      findSwitchControlsWithReturn(account?.id);
    }
  }, [account]);

  useEffect(() => {
    findDevices(roomId);
  }, []);
  useEffect(() => {
    getRoom(roomId);
  }, []);
  useEffect(() => {
    if (!account) getAccount(uuid);
  }, []);

  useEffect(() => {
    if (account && account?.id) {
      getSwitchControlsWithReturnCallback();
    }
  }, [account]);

  return (
    <>
      <MainNavbar />
      <ContentDevices>
        <ContentTitle>
          <BackRoomsTitle>
            <MdArrowBackIos
              size={30}
              onClick={() => history.push(`/${uuid}`)}
            />
          </BackRoomsTitle>
          <NameRoomTitle>{rooms[0]?.name}</NameRoomTitle>
          <AddDeviceTitle>
            <Button
              variant="outline-ligth"
              className="ms-1"
              onClick={() => setModalNewDeviceIsOpen(true)}
            >
              <MdOutlineAdd color="#fff" />
            </Button>
          </AddDeviceTitle>
        </ContentTitle>
        <Modal
          isOpen={modalNewDeviceIsOpen}
          style={{
            overlay: {
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
            },
            content: {
              maxWidth: '500px',
              margin: '0 auto',
              inset: 10,
            },
          }}
          contentLabel="Novo Comodo"
        >
          {newDevice && newDevice?.id ? <h2>Editar Dispositivo</h2> : <h2>Novo Dispositivo</h2>}
          <Form onSubmit={handleSubmitDevice}>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Nome</Form.Label>
              <InputGroup className="mb-2">
                <Form.Control
                  type="text"
                  placeholder="Nome"
                  value={newDevice.name}
                  onChange={(event) => setNewDevice({ ...newDevice, name: event.target.value })}
                />
                <InputGroup.Text>
                  <Button
                    disabled={!newDevice.name || showQrCodeReader}
                    style={(!newDevice.name || showQrCodeReader) ? { opacity: 0.3 } : {}}
                    variant="outline-dark"
                    className="mt-2 mb-2"
                    onClick={() => setShowQrcodeReader(true)}
                  >
                    <ImQrcode size={25} />
                  </Button>
                </InputGroup.Text>
              </InputGroup>
            </Form.Group>
            {newDevice?.mac && (
              <div className="mb-4">
                {newDevice?.id && (
                  <LabelDeviceType>
                    {String(newDevice.deviceType).toLocaleUpperCase()}
&nbsp;
                    {newDevice.registration.nOfChanels === 1 && `${newDevice.registration.nOfChanels} canal`}
                    {newDevice.registration.nOfChanels > 1 && `${newDevice.registration.nOfChanels} canais`}
                    &nbsp;/&nbsp;
                    {newDevice.mac}
                    &nbsp;/&nbsp;Modelo:
                    {' '}
                    {newDevice.registration.hardwareVersion}
                    &nbsp;/&nbsp;Lote:
                    {' '}
                    {newDevice.registration.batch}
                  </LabelDeviceType>
                )}
                {!newDevice?.id && (
                  <LabelDeviceType>
                    {String(newDevice?.deviceType).toLocaleUpperCase()}
&nbsp;
                    {newDevice?.nOfChanels === 1 && `${newDevice?.nOfChanels} canal`}
                    {newDevice?.nOfChanels > 1 && `${newDevice?.nOfChanels} canais`}
                    &nbsp;/&nbsp;
                    {newDevice?.mac}
                    &nbsp;/&nbsp;Modelo:
                    {' '}
                    {newDevice?.hardwareVersion}
                    &nbsp;/&nbsp;Lote:
                    {' '}
                    {newDevice?.batch}
                  </LabelDeviceType>
                )}
              </div>
            )}

            {(!showQrCodeReader && newDevice.controls) && newDevice.controls.map((ch) => (
              <Row className="mt-2 g-2">
                <Col md>
                  <InputGroup className="mb-2">
                    <InputGroup.Text>
                      {ch.switchIndex}
                    </InputGroup.Text>
                    <Form.Control
                      inline
                      type="text"
                      placeholder={`Nome Canal ${ch.switchIndex}`}
                      value={ch.name}
                      onChange={(event) => handleChangeControlName(ch.switchIndex, event.target.value)}
                    />
                    {newDevice?.deviceType === 'switch' && (
                      <InputGroup.Text>
                        <Button
                          variant="outline-dark"
                          onClick={() => handleChangeControlIsReturn(ch.switchIndex, !ch.withoutReturn)}
                        >
                          {ch.withoutReturn ? <BsLightbulbOff size={25} /> : <BsLightbulb size={25} />}
                        </Button>
                      </InputGroup.Text>
                    )}
                  </InputGroup>
                </Col>
              </Row>
            ))}
            {!!showQrCodeReader && (
              <>
                <QrReader
                  delay={300}
                  onError={handleError}
                  onScan={handleScan}
                  style={{ width: '100%' }}
                />
                <QrCancelButton variant="secondary" onClick={() => setShowQrcodeReader(false)}>Cancelar</QrCancelButton>
              </>
            )}
            {!showQrCodeReader && (
              <FooterContent>
                <DeviceButton
                  variant="primary"
                  type="submit"
                  className="float-end ms-1"
                  disabled={!newDevice?.mac || !newDevice?.name}
                >
                  Salvar
                </DeviceButton>
                {!newDevice?.id && (
                  <DeviceButton
                    variant="light"
                    className="float-end ms-1"
                    onClick={() => setNewDevice({})}
                  >
                    Limpar
                  </DeviceButton>
                )}
                <DeviceButton
                  variant="secondary"
                  type="button"
                  className="float-end ms-1"
                  onClick={closeModal}
                >
                  Cancelar
                </DeviceButton>
                {newDevice?.id && (
                  <DeviceButton
                    variant="outline-danger"
                    type="button"
                    className="float-end ms-1"
                    onClick={() => setModalDeleteDeviceIsOpen(true)}
                  >
                    Excluir
                  </DeviceButton>
                )}
              </FooterContent>
            )}

          </Form>
        </Modal>

        {devices.map((dv) => (
          <Card style={{
            maxWidth: 500, width: '92%', color: '#000', marginTop: 15,
          }}
          >
            <Card.Body>
              <Card.Title>
                <DeviceNameContent>
                  <div className="float-start ms-1">{dv.name}</div>
                  <Button
                    variant="outline-dark"
                    className="float-end ms-1"
                    onClick={() => handleOpenEditDevice(dv)}
                  >
                    <MdOutlineEdit />
                  </Button>
                </DeviceNameContent>
                <div>
                  <LabelDeviceType>
                    {String(dv.deviceType).toLocaleUpperCase()}
&nbsp;
                    {dv.registration.nOfChanels === 1 && `${dv.registration.nOfChanels} canal`}
                    {dv.registration.nOfChanels > 1 && `${dv.registration.nOfChanels} canais`}
                    &nbsp;/&nbsp;
                    {dv.mac}
                    &nbsp;/&nbsp;Modelo:
                    {' '}
                    {dv.registration.hardwareVersion}
                    &nbsp;/&nbsp;Lote:
                    {' '}
                    {dv.registration.batch}
                  </LabelDeviceType>
                </div>
              </Card.Title>
            </Card.Body>
            <ListGroup className="list-group-flush">
              {_.sortBy(dv.switchControls, (ch) => ch.switchIndex).map((sc) => (
                <ListGroupItem>
                  {sc.id === switchControleEditId && (
                    <Container>
                      <Row>
                        <Col>
                          <InputGroup className="mb-2">
                            <InputGroup.Text>{sc.switchIndex}</InputGroup.Text>
                            <Form.Control
                              inline
                              className="ms-2"
                              style={{ display: 'inline' }}
                              type="text"
                              placeholder={`Nome Canal ${sc.switchIndex}`}
                              value={editControlName}
                              onChange={(event) => setEditControlName(event.target.value)}
                            />
                            <InputGroup.Text>
                              <Button
                                variant="outline-dark"
                                onClick={() => setEditControlWithoutReturn(!editControlWithoutReturn)}
                              >
                                {editControlWithoutReturn ? <BsLightbulbOff size={25} /> : <BsLightbulb size={25} />}
                              </Button>
                            </InputGroup.Text>
                          </InputGroup>
                          {!!editControlWithoutReturn && (
                            <FloatingLabel controlId="floatingSelect" label="Master">
                              <Form.Select
                                aria-label="Floating label select example"
                                onChange={(event) => setEditControlControlMasterId(event.target.value)}
                                value={editControlMasterId}
                              >
                                <option>Nenhum</option>
                                {switchControlsWithReturn.map(((swr) => <option value={swr.id}>{swr.name}</option>))}
                              </Form.Select>
                            </FloatingLabel>
                          )}
                        </Col>
                      </Row>
                      {sc.fourwaySlaves.length > 0 && (
                        <Row>
                          <div><b>Switchs vinculados</b></div>
                          {sc.fourwaySlaves.map((fs) => <div>{fs.name}</div>)}
                        </Row>
                      )}
                      <Row className="mt-3">
                        <Col>
                          <Button
                            variant="secondary"
                            className="float-end ms-1"
                            onClick={() => handleCancelSwitchControlEdit()}
                          >
                            Cancelar
                          </Button>
                          <Button
                            className="float-end ms-1"
                            onClick={() => handleSaveSwitchControlEdit(sc.id)}
                          >
                            Salvar
                          </Button>
                        </Col>
                      </Row>
                    </Container>
                  )}
                  {sc.id !== switchControleEditId && (
                    <>
                      {sc.switchIndex}
                      {' '}
                      -
                      {sc.name}
&nbsp;&nbsp;
                      {sc.withoutReturn && (
                        <Badge bg={sc.fourwayMasterId ? 'success' : 'secondary'}>
                          <BsLightbulbOff />
                        </Badge>
                      )}
                      {sc.fourwaySlaves.length > 0 && (
                        <Badge
                          bg="success"
                          onClick={() => handleEnableSwitchControlEdit(
                            sc.id,
                            sc.name,
                            sc.withoutReturn,
                            sc.fourwayMasterId,
                          )}
                        >
                          {sc.fourwaySlaves.length}
                          {' '}
                          <GoRepoForked />
                        </Badge>
                      )}
                      <Button
                        variant="light"
                        className="float-end"
                        onClick={() => handleEnableSwitchControlEdit(
                          sc.id,
                          sc.name,
                          sc.withoutReturn,
                          sc.fourwayMasterId,
                        )}
                      >
                        <MdOutlineEdit />
                      </Button>
                    </>
                  )}
                </ListGroupItem>
              ))}
            </ListGroup>
            <ListGroup className="list-group-flush">
              {_.sortBy(dv.smartplugControls, (ch) => ch.switchIndex).map((sp) => (
                <ListGroupItem>
                  {sp.id === smartplugControleEditId && (
                    <Container>
                      <Row>
                        <Col>
                          <InputGroup className="mb-2">
                            <InputGroup.Text>{sp.switchIndex}</InputGroup.Text>
                            <Form.Control
                              inline
                              className="ms-2"
                              style={{ display: 'inline' }}
                              type="text"
                              placeholder={`Nome Canal ${sp.switchIndex}`}
                              value={editControlName}
                              onChange={(event) => setEditControlName(event.target.value)}
                            />
                          </InputGroup>
                        </Col>
                      </Row>
                      <Row className="mt-3">
                        <Col>
                          <Button
                            variant="secondary"
                            className="float-end ms-1"
                            onClick={() => handleCancelSmartplugControlEdit()}
                          >
                            Cancelar
                          </Button>
                          <Button
                            className="float-end ms-1"
                            onClick={() => handleSaveSmartplugControlEdit(sp.id)}
                          >
                            Salvar
                          </Button>
                        </Col>
                      </Row>
                    </Container>
                  )}
                  {sp.id !== smartplugControleEditId && (
                    <>
                      {sp.switchIndex}
                      {' '}
                      -
                      {sp.name}
&nbsp;&nbsp;
                      <Button
                        variant="light"
                        className="float-end"
                        onClick={() => handleEnableSmartplugControlEdit(sp.id, sp.name)}
                      >
                        <MdOutlineEdit />
                      </Button>
                    </>
                  )}
                </ListGroupItem>
              ))}
            </ListGroup>
          </Card>
        ))}
      </ContentDevices>
      <ModalDialog show={modalDeleteDeviceIsOpen}>
        <ModalDialog.Header>
          <ModalDialog.Title>Excluir dispositivo</ModalDialog.Title>
        </ModalDialog.Header>

        <ModalDialog.Body>
          <p>Esta exclusao não poderá ser desfeita!</p>
          <p>Deseja realmente excluir este dispositivo?</p>
        </ModalDialog.Body>

        <ModalDialog.Footer>
          <Button
            variant="secondary"
            onClick={() => setModalDeleteDeviceIsOpen()}
          >
            Cancelar
          </Button>
          <Button
            onClick={() => handleRemoveDevice()}
            variant="danger"
          >
            Excluir
          </Button>
        </ModalDialog.Footer>
      </ModalDialog>
    </>
  );
}
