import styled from 'styled-components';
import { Button } from 'react-bootstrap';

export const ContentDevices = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: left;
`;

export const DeviceTd = styled.td`
  min-width: 200px;
`;

export const DeviceButton = styled(Button)`
  margin: 2px;
`;

export const QrCancelButton = styled(Button)`
  margin-top: 10px;
  width: 100%;
`;
export const FooterContent = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
`;

export const LabelDeviceType = styled.span`
  font-size: 12px;
`;

export const DeviceNameContent = styled.div`
  display: inline-block;
  width: 100%;
`;

export const ContentTitle = styled.div`
  margin-top: 10px;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between
`;

export const BackRoomsTitle = styled.div`
  align-self: flex-start;
  margin-left: 15px;
  padding-top: 4px;
`;

export const AddDeviceTitle = styled.div`
  align-self: flex-end;
  margin-right: 15px;
  border: 1px solid #fff;
  border-radius: 10px;
`;

export const NameRoomTitle = styled.h6`
  text-align: center;
`;
