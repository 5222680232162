import React, { createContext, useContext, useState } from 'react';
import { services } from '../api/feathers';

const AccountsContext = createContext();

export function AccountsProvider({ children }) {
  const [account, setAccount] = useState([]);

  function getAccount(uuid) {
    services.accounts.find({ query: { uuid } }).then((response) => setAccount(response.data[0]));
  }

  return (
    <AccountsContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        account,
        getAccount,
      }}
    >
      { children }
    </AccountsContext.Provider>
  );
}

export function useAccounts() {
  const context = useContext(AccountsContext);

  return context;
}
