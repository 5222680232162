import React, { useEffect } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from 'react-router-dom';
import { useAuth } from '../contexts/useAuth';
// import { Dashboard } from "./Dashboard";
import Login from './Login';
import ListRooms from './ListRooms';
import ListDevices from './ListDevices';

export default function Navigation() {
  const { token, validateToken } = useAuth();
  console.log('token', token);
  useEffect(() => {
    if (!token) {
      validateToken();
    }
  }, []);

  if (!token) {
    return (
      <Router>
        <Switch>
          <Route path="/:uuid">
            <Login />
          </Route>
          <Route path="/">
            <h1>Acesso negado Login!</h1>
          </Route>
        </Switch>
      </Router>
    );
  }
  return (
    <Router>
      <Switch>
        <Route path="/:uuid" exact>
          <ListRooms />
        </Route>
        <Route path="/:uuid/room/:roomId" exact>
          <ListDevices />
        </Route>
        <Route path="/">
          <h1>Acesso negado!</h1>
        </Route>
      </Switch>
    </Router>
  );
}
