import React, { createContext, useContext, useState } from 'react';
import { services } from '../api/feathers';
import { useSmartplugControls } from './useSmartplugControls';
import { useSwitchControls } from './useSwitchControls';

const DevicesContext = createContext();

export function DevicesProvider({ children }) {
  const [devices, setDevices] = useState([]);
  const { createSwitchControls } = useSwitchControls();
  const { createSmartplugControls } = useSmartplugControls();

  async function validateDevicesRegistrations(deviceMac) {
    return services.deviceRegistrations.find({
      query: {
        mac: deviceMac,
      },
    }).then((response) => {
      if (response.data.length > 0) {
        console.log('OK DEVICE REGISTRATIONS');
        return response.data[0];
      }
      console.log('ERRO DEVICE REGISTRATIONS');
      throw new Error(`Dispositivo ${deviceMac} não registrado no sistema.`);
    });
  }

  function findDevices(roomId) {
    services.devices.find({
      query: {
        roomId,
        includeControls: true,
      },
    }).then((response) => setDevices(response.data));
  }

  async function createDevices(device) {
    const { roomId, deviceType } = device;

    return services.devices.create(device)
      .then(async (response) => {
        if (deviceType === 'switch') {
          device.controls.map((ct) => {
            createSwitchControls({ deviceId: response.id, ...ct });
          });
        }

        if (deviceType === 'smartplug') {
          device.controls.map((ct) => {
            createSmartplugControls({ deviceId: response.id, ...ct });
          });
        }

        findDevices(roomId);
      });
  }

  async function patchDevices(device) {
    services.devices.patch(device.id, device)
      .then(() => {
        findDevices(device.roomId);
      });
  }

  async function removeDevices(deviceId) {
    const { roomId, deviceType } = devices.find((dv) => dv.id === deviceId);

    if (deviceType === 'switch') {
      const { data: masterSwitchControlsList } = await services.switchControls.find({ query: { deviceId } });

      masterSwitchControlsList.forEach(async (sc) => {
        services.switchControls.find({ query: { fourwayMasterId: sc.id } })
          .then(({ data: slavesSwitchControlsList }) => {
            slavesSwitchControlsList.forEach((ssc) => {
              services.switchControls.patch(ssc.id, { fourwayMasterId: null });
            });
          });
      });
      masterSwitchControlsList.forEach(async (sc) => {
        await services.switchControls.remove(sc.id);
      });
    }
    if (deviceType === 'ir') {
      const { data: irControlsList } = await services.irControls.find({ query: { deviceId } });
      irControlsList.forEach(async (ir) => {
        await services.irControls.remove(ir.id);
      });
    }
    if (deviceType === 'rf') {
      const { data: rfControlsList } = await services.rfControls.find({ query: { deviceId } });
      rfControlsList.forEach(async (ir) => {
        await services.rfControls.remove(ir.id);
      });
    }
    if (deviceType === 'smartplug') {
      const { data: smartplugControlsList } = await services.smartplugControls.find({ query: { deviceId } });
      smartplugControlsList.forEach(async (ir) => {
        await services.smartplugControls.remove(ir.id);
      });
    }
    await services.devices.patch(deviceId, { mac: `deleted_mac_${deviceId}` });
    await services.devices.remove(deviceId);
    findDevices(roomId);
  }

  return (
    <DevicesContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        devices,
        findDevices,
        createDevices,
        patchDevices,
        removeDevices,
        validateDevicesRegistrations,
      }}
    >
      { children }
    </DevicesContext.Provider>
  );
}

export function useDevices() {
  const context = useContext(DevicesContext);

  return context;
}
