import React from 'react';
import {
  Button, Container, Nav, Navbar,
} from 'react-bootstrap';
import icon from '../../assets/logo-smartek-icone.svg';
import { useAuth } from '../../contexts/useAuth';

export default function MainNavbar() {
  const { logout } = useAuth();
  return (
    <Navbar bg="light" expand="lg">
      <Container fluid>
        <Navbar.Brand href="#">
          <img src={icon} alt="" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll">
          <Nav
            className="me-auto my-2 my-lg-0"
            style={{ maxHeight: '100px' }}
            navbarScroll
          >
            <Nav.Link href="#">Suporte</Nav.Link>
            <Nav.Link href="#">Perfil</Nav.Link>
            <Nav.Link href="#">
              <Button onClick={logout}>
                Sair
              </Button>
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}
