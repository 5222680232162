import styled from 'styled-components';
import { Button } from 'react-bootstrap';

export const ContentRooms = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: left;
`;

export const RoomTd = styled.td`
  min-width: 200px;
`;

export const RoomButton = styled(Button)`
  margin: 2px;
`;

export const RoomTitleContent = styled.div`
  display: inline-block;
  width: 100%;
`;
