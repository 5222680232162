import {
  useCallback, useEffect, React, useState,
} from 'react';
import {
  Form, Button, Card, ListGroup, ListGroupItem,
} from 'react-bootstrap';
import Modal from 'react-modal';
import { Link, useHistory, useParams } from 'react-router-dom';
import { MdOutlineEdit, MdOutlineAdd } from 'react-icons/md';
import { toast } from 'react-toastify';
import { useAccounts } from '../../contexts/useAccounts';
import { useRooms } from '../../contexts/useRooms';
import { RoomButton, RoomTitleContent, ContentRooms } from './styles';
import MainNavbar from '../MainNavbar';

export default function ListRooms() {
  const [modalNewRoomIsOpen, setModalNewRoomIsOpen] = useState(false);
  const [newRoom, setNewRoom] = useState({});
  // eslint-disable-next-line no-unused-vars
  const history = useHistory();
  const { uuid } = useParams();
  const { account, getAccount } = useAccounts();
  const {
    rooms,
    findRooms,
    createRooms,
    patchRooms,
    removeRooms,
  } = useRooms();

  function closeModal() {
    setModalNewRoomIsOpen(false);
    setNewRoom({});
  }

  function handleSubmitRoom(e) {
    e.preventDefault();
    const { name, id } = newRoom;
    if (!name) {
      toast.warn('Informe o nome do cômodo');
    } else {
      console.log(newRoom);
      if (id) {
        patchRooms(newRoom)
          .then(() => {
            closeModal();
          });
      } else {
        createRooms({ accountId: account.id, name })
          .then(() => {
            closeModal();
          });
      }
    }
  }

  function handleRemoveRoom(roomId) {
    removeRooms(roomId).then(() => {
      closeModal();
    }).catch((error) => {
      toast.error(error.message);
    });
  }

  function handleOpenEditRoom(room) {
    console.log('NewRoom: ', room);
    setNewRoom(room);
    setModalNewRoomIsOpen(true);
  }

  const getRoomsByAccountId = useCallback(() => {
    if (account && account?.id) {
      findRooms(account?.id);
    }
  }, [account]);

  useEffect(() => {
    getAccount(uuid);
  }, []);

  useEffect(() => {
    getRoomsByAccountId();
  }, [account]);

  return (
    <>
      <MainNavbar />
      <ContentRooms>
        <Modal
          isOpen={modalNewRoomIsOpen}
          onRequestClose={closeModal}
          style={{
            overlay: {
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
            },
            content: {
              maxWidth: '500px',
              margin: '0 auto',
              maxHeight: 300,
              inset: 10,
            },
          }}
          contentLabel="Novo Comodo"
        >
          {newRoom && newRoom?.id ? <h2>Editar Cômodo</h2> : <h2>Novo Cômodo</h2>}
          <Form onSubmit={handleSubmitRoom}>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Nome</Form.Label>
              <Form.Control
                type="text"
                placeholder="Nome"
                // required
                value={newRoom.name}
                onChange={(event) => setNewRoom({ ...newRoom, name: event.target.value })}
              />
            </Form.Group>
            <div>

              {!!newRoom.id && (
                <RoomButton
                  variant="danger"
                  className="float-end ml-1"
                  onClick={() => handleRemoveRoom(newRoom.id)}
                >
                  Excluir
                </RoomButton>
              )}
              <RoomButton
                variant="secondary"
                type="button"
                className="float-end ml-1"
                onClick={closeModal}
              >
                Cancelar
              </RoomButton>
              <RoomButton variant="primary" className="float-end ml-1" type="submit">
                Salvar
              </RoomButton>
            </div>
          </Form>
        </Modal>
        <Card style={{
          maxWidth: 500, width: '92%', color: '#000', marginTop: 15,
        }}
        >
          <Card.Body>
            <Card.Title>
              <RoomTitleContent>
                <div className="float-start ms-1">Cômodos</div>
                <Button
                  variant="outline-dark"
                  className="float-end ms-1"
                  onClick={() => setModalNewRoomIsOpen(true)}
                >
                  <MdOutlineAdd />
                </Button>
              </RoomTitleContent>
            </Card.Title>
          </Card.Body>
          <ListGroup className="list-group-flush">
            {rooms.map((rm) => (
              <ListGroupItem key={rm.id}>
                <Link to={`/${uuid}/room/${rm.id}`}>{rm.name}</Link>
                <Button
                  variant="light"
                  className="float-end"
                  onClick={() => handleOpenEditRoom(rm)}
                >
                  <MdOutlineEdit />
                </Button>
              </ListGroupItem>
            ))}
          </ListGroup>
        </Card>
      </ContentRooms>
    </>
  );
}
