import React, { createContext, useContext, useState } from 'react';
import { services } from '../api/feathers';

const SmartplugControlContext = createContext();

export function SmartplugControlProvider({ children }) {
  const [smartplugControls, setSmartplugControls] = useState([]);

  function findSmartplugControls(deviceId) {
    services.smartplugControls.find({
      query: {
        deviceId,
      },
    }).then((response) => setSmartplugControls(response.data));
  }

  async function createSmartplugControls(smartplugControl) {
    const { deviceId } = smartplugControl;

    services.smartplugControls.create(smartplugControl)
      .then(() => {
        findSmartplugControls(deviceId);
      });
  }

  async function patchSmartplugControls(smartplugControlId, smartplugControl) {
    return services.smartplugControls.patch(smartplugControlId, smartplugControl);
  }

  /*   async function removeSmartplugControls(smartplugControl) {
    services.smartplugControls.remove(smartplugControl.id)
      .then(() => {
        findSmartplugControls(smartplugControl.deviceId);
      });
  } */

  return (
    <SmartplugControlContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        smartplugControls,
        findSmartplugControls,
        createSmartplugControls,
        patchSmartplugControls,
      }}
    >
      { children }
    </SmartplugControlContext.Provider>
  );
}

export function useSmartplugControls() {
  const context = useContext(SmartplugControlContext);

  return context;
}
